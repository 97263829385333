// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "q_rb d_gs d_cp";
export var heroHeaderCenter = "q_gt d_gt d_cp d_ds";
export var heroHeaderRight = "q_gv d_gv d_cp d_dt";
export var heroParagraphLeft = "q_rc d_gp d_cs";
export var heroParagraphCenter = "q_gq d_gq d_cs d_ds";
export var heroParagraphRight = "q_gr d_gr d_cs d_dt";
export var heroBtnWrapperLeft = "q_rd d_dZ d_dY d_s d_bw d_bC";
export var heroBtnWrapperCenter = "q_rf d_d0 d_dY d_s d_bw d_bz";
export var heroBtnWrapperRight = "q_rg d_d1 d_dY d_s d_bw d_bD";
export var overlayBtnWrapper = "q_rh d_gn d_X d_4 d_5 d_6 d_bk d_b8";
export var design4 = "q_rj d_gm d_X d_4 d_5 d_bk";
export var heroExceptionSmall = "q_rk s_rk";
export var heroExceptionNormal = "q_rl s_rl";
export var heroExceptionLarge = "q_rm s_rm";
export var Title1Small = "q_rn s_rn s_rD s_rF";
export var Title1Normal = "q_rp s_rp s_rD s_rG";
export var Title1Large = "q_rq s_rq s_rD s_rH";
export var BodySmall = "q_rr s_rr s_rD s_rX";
export var BodyNormal = "q_rs s_rs s_rD s_rY";
export var BodyLarge = "q_rt s_rt s_rD s_rZ";