// extracted by mini-css-extract-plugin
export var datasheetRowWrapperCenter = "n_p5 d_bz";
export var datasheetRowWrapper = "n_lx d_lx d_bG";
export var datasheetLeftWrapper = "n_p6 d_bw d_bL";
export var datasheetWrapperFull = "n_p7 d_cz";
export var datasheetWrapperFullLeft = "n_nK d_cz d_bw d_bL d_bz d_bQ";
export var contentWrapper = "n_mC d_ly";
export var contentWrapperCenter = "n_p8 d_lD";
export var datasheetLeftWrapperCenter = "n_p9 d_lB d_bw d_bz d_bL d_bF";
export var datasheetImageCenterWrapper = "n_lv d_lv d_s d_cr";
export var datasheetRightWrapperCenter = "n_qb d_lz";
export var datasheetFooter = "n_lC d_lC";
export var alignLeft = "n_qc d_dr";
export var alignCenter = "n_bL d_ds";
export var alignRight = "n_qd d_dt";
export var datasheetInnerWrapperNewLeft = "n_qf d_fb d_L d_W d_bw d_bL d_df";
export var datasheetInnerWrapperLeft = "n_qg d_d9 d_L d_W d_bw d_bL d_b2 d_df d_c6";
export var datasheetInnerWrapperRight = "n_qh d_fb d_L d_W d_bw d_bL d_df";
export var datasheetRowWrapperReverse = "n_qj d_bG";
export var datasheetSubtitle = "n_qk d_ct";
export var tableRow = "n_ql";
export var cell = "n_qm";
export var tableRowWrapper = "n_qn d_s d_dr";
export var tableHeadingCell = "n_qp";
export var tableHeading = "n_qq";
export var tableRowStriped = "n_qr";
export var tableRowHeading = "n_qs";
export var dataTable = "n_qt d_s";
export var imageWrapper = "n_qv d_fc d_W";
export var imageWrapperFull = "n_qw d_D d_s d_bb d_W";
export var imageWrapperIcon = "n_qx";
export var titleMargin = "n_qy d_cp";
export var datasheetInnerInnerWrapper = "n_qz d_s";
export var hasLabels = "n_qB";
export var label = "n_qC";
export var SmallSmall = "n_qD s_qD s_rD s_r0";
export var SmallNormal = "n_qF s_qF s_rD s_r1";
export var SmallLarge = "n_qG s_qG s_rD s_rY";