// extracted by mini-css-extract-plugin
export var lbContainerOuter = "v_sY";
export var lbContainerInner = "v_sZ";
export var movingForwards = "v_s0";
export var movingForwardsOther = "v_s1";
export var movingBackwards = "v_s2";
export var movingBackwardsOther = "v_s3";
export var lbImage = "v_s4";
export var lbOtherImage = "v_s5";
export var prevButton = "v_s6";
export var nextButton = "v_s7";
export var closing = "v_s8";
export var appear = "v_s9";